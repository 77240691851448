import { ViewerGroupType } from '@modules/viewer/ViewerTypes';
import { useLocationResolver } from '@routes/routesHooks';
import { StudyResolver } from '@routes/study/RoutesStudy';
import { filteredTreeData } from '@shared/utils/Viewer';
import { useCallback, useState } from 'react';
import { INodeRendererProps } from 'react-accessible-treeview';
import { useStoreAggregation, useTableListSider } from './tableListSiderHooks';
import { TableListSiderTemplate, TableListSiderTreeItem } from './TableListSiderTemplate';
import { ITableListSiderProps } from './TableListSiderTypes';

export const TableListSider = ({
  selectedTable,
  defaultTableName,
  defaultFolderName,
  defaultFolderId,
  onSelectTable,
  refetch,
  draggable,
}: ITableListSiderProps) => {
  const { state: locationState } = useLocationResolver<StudyResolver['dataViewer']['params'], { external?: boolean }>();
  const isExternalRoute = locationState?.external;
  const { treeData, globalStudy, tablesQuery } = useStoreAggregation(draggable);
  const [searchText, setSearchText] = useState('');

  const filteredTree = searchText ? filteredTreeData(treeData, searchText) : treeData;

  const { expandedGroups, selectedKeys, onSelect, onExpand, onDragStart } = useTableListSider({
    globalStudyId: globalStudy?.id!,
    treeData,
    defaultTableName,
    defaultFolderName,
    defaultFolderId,
    selectedTable,
    onSelectTable,
  });

  const loadingMap: Partial<Record<ViewerGroupType, boolean>> = {
    external: tablesQuery.isFetching,
    internal: tablesQuery.isFetching,
    rccGroups: tablesQuery.isFetching,
    studyRT: tablesQuery.isFetching,
  };

  const isLoadingData = Object.values(loadingMap).some((el) => el);

  const customTitle = useCallback(
    (node: INodeRendererProps, draggable?: boolean) => (
      <TableListSiderTreeItem
        node={node}
        draggable={draggable}
        refetch={refetch}
        onSelectTable={onSelectTable}
        selectedTable={selectedTable}
        isExternalRoute={isExternalRoute}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tablesQuery.isFetching, selectedTable, onSelectTable],
  );

  const reloadTables = () => {
    tablesQuery.refetch();
    if (selectedTable) {
      refetch();
    }
  };

  return (
    <TableListSiderTemplate
      treeData={filteredTree}
      selectedKeys={selectedKeys}
      expandedKeys={expandedGroups}
      draggable={draggable}
      titleRender={customTitle}
      onExpand={onExpand}
      onDragStart={onDragStart}
      onSelect={onSelect}
      refetch={refetch}
      isErrorLoadingTables={tablesQuery.isError}
      reloadTables={reloadTables}
      isLoadingData={isLoadingData}
      setSearch={setSearchText}
    />
  );
};
