import { Typography, notification, Table, Link } from '@ui';
import { dateToString } from '@shared/utils/Date';
import routes from '@routes';
import { getStudyPathName } from '@routes/utils';
import { SourceApiRoutes } from '@modules/source/duck/sourceApi';
import { StoreApiRoutes } from '@modules/stores/duck/storeApi';
import { useLazySimpleQuerySubscription } from '@config/appApi';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { getActionTitle, getKindTitle } from '@modules/audit/duck/auditHelpers';
import { ActorAndInfo } from '@components';
import { AuditModalsType } from '@modules/audit/modals/AuditModalsConstants';
import { auditActions } from '@modules/audit/duck/auditSlice';
import { JobApiRoutes } from '@modules/job/duck/jobApi';
import { SYSTEM_STORE_NAMES } from '@modules/stores/duck/storeConstants';
import { isCrossStudy } from '@shared/utils/common';
import { parseCodeLabFilePath } from '@modules/codeLab/components/CodeLabFilePath';
import { TableColumnsType, TableProps } from 'antd';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AuditLogItem, AuditLogItemAction, AuditLogItemKind, AuditLogListResponse } from '../AuditTypes';

const getEntityLink = (kind: AuditLogItemKind, id: number | string, name: string, studyId: number) => {
  switch (kind) {
    case 'SOURCE':
      return {
        href: routes[getStudyPathName(studyId)].sources.root.resolver({ studyId }),
        api: SourceApiRoutes.list,
        // href: routes.study.sources.view(id as string, 'audit'),
        // api: SourceApiRoutes.list(id as string),
      };
    case 'MODEL':
      return {
        // there are no such page in cross-study so route was leave as is
        href: routes.study.models.view.resolver({ studyId, modelId: id }),
      };
    case 'STORE':
      return {
        href: routes[getStudyPathName(studyId)].stores.root.resolver({ studyId }),
        api: StoreApiRoutes.list,
      };
    case 'FILE':
      return {
        href: routes[getStudyPathName(studyId)].jobs.view.resolver({ studyId, jobId: id }),
        api: JobApiRoutes.list,
      };
    case 'TABLE':
      return {
        href: routes[getStudyPathName(studyId)].dataViewer.resolver({ studyId, tableId: name }),
        api: JobApiRoutes.list,
      };
    case 'DATASET':
      return {
        href: routes[getStudyPathName(studyId)].dataViewer.resolver({
          studyId,
          tableId: name,
          tableFolderName: isCrossStudy(studyId) ? SYSTEM_STORE_NAMES.GLOBAL_INTERNALS : SYSTEM_STORE_NAMES.INTERNALS,
        }),
      };
    case 'STACK_DATASET':
      return {
        href: routes[getStudyPathName(studyId)].dataViewer.resolver({
          studyId,
          tableId: name,
          tableFolderName: SYSTEM_STORE_NAMES.GLOBAL_STACK_DATA,
        }),
      };
    case 'AO':
      return {
        href: routes[getStudyPathName(studyId)].analysisObjectsModels.root.resolver({ studyId }),
      };
    case 'AP':
      return {
        href: routes[getStudyPathName(studyId)].analysisPackage.resolver({ studyId }),
      };
    case 'DEPLOYMENT':
      return {
        href: routes[getStudyPathName(studyId)].deploymentPlans.resolver({ studyId }),
      };
    case 'CL_SCHEDULER':
      return {
        href: routes[getStudyPathName(studyId)].codeLab.scheduler.root.resolver({ studyId }),
      };
    default:
      return {
        href: '#',
      };
  }
};

export const AuditLogList = ({ disableKindSort, data, loading, pagination, studyId, onChange }: AuditLogListProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [triggerSimpleQuery] = useLazySimpleQuerySubscription();
  const navigate = useNavigate();
  const { getColumnSearchProps, getColumnDateRangeFilterProps, locale } = useColumnSearch<AuditLogItem>(
    t('audit.table.filter.noData'),
  );

  const openDiffModal = (data: AuditLogItem) => {
    dispatch(auditActions.pushModal({ type: AuditModalsType.showDetails, data }));
  };

  const columns: TableColumnsType<AuditLogItem> = useMemo(
    () => [
      {
        width: 220,
        title: t('audit.table.column.date'),
        dataIndex: 'action_date',
        sorter: (a, b) => 0,
        sortDirections: ['ascend'],
        ...getColumnDateRangeFilterProps('action_date'),
        onFilter: undefined,
        render: (action_date: number) => <Typography.Text>{dateToString(action_date)}</Typography.Text>,
      },
      {
        width: 250,
        customKey: 'narrow',
        title: t('audit.table.column.username'),
        dataIndex: 'user_name',
        ...getColumnSearchProps('user_name'),
        onFilter: undefined,
        render: (_, record) => <ActorAndInfo info={record.ip} actor={record.user_name} />,
      },
      {
        width: 150,
        title: t('audit.table.column.action'),
        dataIndex: 'action',
        filters: [
          {
            text: t('audit.table.created'),
            value: 'CREATED',
          },
          {
            text: t('audit.table.updated'),
            value: 'UPDATED',
          },
          {
            text: t('audit.table.deleted'),
            value: 'DELETED',
          },
        ],
        render: (action: AuditLogItemAction) => getActionTitle(t, action),
      },
      {
        width: 200,
        title: t('audit.table.column.kind'),
        dataIndex: 'kind',
        render: (kind: AuditLogItemKind) => getKindTitle(t, kind),
        ...(disableKindSort
          ? {}
          : {
              filters: [
                {
                  text: t('audit.table.store'),
                  value: 'STORE',
                },
                {
                  text: t('audit.table.source'),
                  value: 'SOURCE',
                },
                {
                  text: t('audit.table.model'),
                  value: 'MODEL',
                },
                {
                  text: t('audit.table.file'),
                  value: 'FILE',
                },
                {
                  text: t('audit.table.table'),
                  value: 'TABLE',
                },
                {
                  text: t('audit.table.dataset'),
                  value: 'DATASET',
                },
                {
                  text: t('audit.table.stackDataset'),
                  value: 'STACK_DATASET',
                },
                {
                  text: t('audit.table.ao'),
                  value: 'AO',
                },
                {
                  text: t('audit.table.ap'),
                  value: 'AP',
                },
                {
                  text: t('audit.table.deploymentPlan'),
                  value: 'DEPLOYMENT',
                },
                {
                  text: t('audit.table.clScheduler'),
                  value: 'CL_SCHEDULER',
                },
              ],
            }),
      },
      {
        width: 350,
        title: t('audit.table.column.name'),
        dataIndex: 'entity_name',
        ...getColumnSearchProps('entity_name'),
        onFilter: undefined,
        render: (entityName: string, record) => (
          <Typography.Text strong ellipsis>
            <Link
              title={parseCodeLabFilePath(entityName)}
              to={getEntityLink(record.kind, record.entity_id, record.entity_name, studyId).href}
              children={parseCodeLabFilePath(entityName)}
              style={{ fontWeight: 'inherit' }}
              disabled={record.deleted}
              onClick={(event) => {
                const entityLink = getEntityLink(record.kind, record.entity_id, record.entity_name, studyId);
                if (entityLink.api) {
                  event.preventDefault();
                  triggerSimpleQuery({ url: entityLink.api })
                    .unwrap()
                    .then(() => {
                      navigate(entityLink.href);
                    })
                    .catch(() => {
                      notification.error({
                        message: t('notification.resourceUnavailable'),
                      });
                    });
                }
              }}
            />
          </Typography.Text>
        ),
      },
      {
        width: 150,
        render: (_, record) => (
          <Typography.Link children={t('audit.table.diff')} onClick={() => openDiffModal(record)} />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  return (
    <Table
      locale={locale}
      columns={columns}
      dataSource={data?.items}
      loading={loading}
      rowKey={(item) => item.id}
      onChange={onChange}
      tableLayout="fixed"
      scroll={{ x: 900 }}
      pagination={pagination}
    />
  );
};

interface AuditLogListProps {
  studyId: number;
  data?: AuditLogListResponse;
  pagination?: TableProps<AuditLogItem>['pagination'];
  onChange?: TableProps<AuditLogItem>['onChange'];
  loading?: boolean;
  pageSize?: number;
  disableKindSort?: boolean;
}
