import { ITask } from '@modules/task/TaskTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ITasksStore {
  runningTasks: ITask[];
}

const initialState: ITasksStore = {
  runningTasks: [],
};

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setRunningTasks: (state, action: PayloadAction<ITask[]>) => {
      state.runningTasks = action.payload;
    },
  },
});

export const tasksActions = tasksSlice.actions;

export const tasksReducer = tasksSlice.reducer;
