import { Badge, Dropdown } from '@ui';
import { NotificationsPanel } from '@app/components/Notifications/components/NotificationsPanel';
import { Bell } from '@components/icons';
import { useMemo } from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { selectNotifications } from './duck/notificationsSelector';

export const NotificationsButton = () => {
  const { archived, latest } = useSelector(selectNotifications);
  const unreadNotifications = useMemo(() => latest.filter((notification) => !notification.isRead), [latest]);

  return (
    <Dropdown
      placement="bottomRight"
      trigger={['click']}
      menu={{
        items: [{ key: 'panel', label: <NotificationsPanel notifications={latest} archived={archived} /> }],
      }}
    >
      <Badge count={unreadNotifications.length} css={cssBadge}>
        <Bell css={cssNotificationsButton} />
      </Badge>
    </Dropdown>
  );
};

const cssBadge = () => css`
  .ant-badge-count {
    height: 18px;
    min-width: 18px;
    box-shadow: 0 0 0 1.5px #ffffff;
    font-size: 12px;
    padding: 0 4px;
    align-items: center;
    position: absolute;
    top: 12px;
    right: 16px;
  }
`;

const cssNotificationsButton = css`
  font-size: 42px;
`;
