import { BaseIcon, SvgIconProps } from '@components/icons/BaseIcon';
import { forwardRef, ForwardRefExoticComponent } from 'react';

export const StackIcon: ForwardRefExoticComponent<SvgIconProps> = forwardRef((props, ref) => (
  <BaseIcon {...props} forwardRef={ref} width={24} height={24} fill="none" viewBox="0 0 24 24">
    <path
      d="M12 4L4 8L12 12L20 8L12 4Z"
      stroke="#12161A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M4 12L12 16L20 12" stroke="#12161A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4 16L12 20L20 16" stroke="#12161A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </BaseIcon>
));
