import { BaseIcon, SvgIconProps } from '@components/icons/BaseIcon';
import { forwardRef, ForwardRefExoticComponent } from 'react';

export const Bell: ForwardRefExoticComponent<SvgIconProps> = forwardRef((props, ref) => (
  <BaseIcon {...props} forwardRef={ref} width={42} height={42} viewBox="0 0 42 42">
    <path
      d="M22.6075 32.1641C23.6166 32.1641 24.1498 33.3587 23.4766 34.1101C23.0393 34.5994 22.5035 34.9908 21.9044 35.2586C21.3052 35.5265 20.6562 35.6646 20 35.6641C19.3437 35.6646 18.6947 35.5265 18.0955 35.2586C17.4964 34.9908 16.9606 34.5994 16.5233 34.1101C15.8793 33.3914 16.339 32.2679 17.263 32.1722L17.3913 32.1652L22.6075 32.1641Z"
      fill="#84919A"
    />
    <path
      d="M20.0003 12.3359C21.5846 12.3359 22.9239 13.3894 23.3544 14.8338L23.4081 15.0333L23.4174 15.0834C24.7036 15.809 25.7995 16.8289 26.6155 18.0596C27.4315 19.2903 27.9444 20.6968 28.1121 22.1639L28.1448 22.4988L28.1669 22.8359V26.2554L28.1914 26.4141C28.3512 27.2737 28.8269 28.0424 29.5249 28.5689L29.7198 28.7054L29.9088 28.8209C30.9121 29.3891 30.5621 30.8813 29.4689 30.9956L29.3336 31.0026H10.6669C9.4676 31.0026 9.04876 29.4113 10.0918 28.8209C10.5363 28.5694 10.9204 28.2236 11.2171 27.8078C11.5137 27.392 11.7158 26.9163 11.8091 26.4141L11.8336 26.2473L11.8348 22.7823C11.9059 21.2585 12.3495 19.7753 13.1266 18.4627C13.9037 17.15 14.9908 16.0478 16.2926 15.2526L16.5819 15.0823L16.5936 15.0321C16.7586 14.3345 17.1337 13.7042 17.6682 13.2265C18.2027 12.7487 18.8709 12.4465 19.5826 12.3604L19.7949 12.3406L20.0003 12.3359Z"
      fill="#84919A"
    />
  </BaseIcon>
));
