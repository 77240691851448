import { appAxios } from '@config/AppConfig';
import { RootState, store } from '@store';
import { TasksApiRoutes } from '@modules/task/duck/taskApi';
import { tasksActions } from '@modules/task/duck/tasksSlice';
import { ITask } from '@modules/task/TaskTypes';

const TASKS_UPDATE_INTERVAL = 10000;

export const fetchTasks = async () => {
  try {
    const rootState: RootState = store.getState();

    if (!rootState.app.ready || rootState.app.isUnauthorized) {
      return;
    }

    const tasks = await appAxios.get<ITask[]>(TasksApiRoutes.tasksRunning, {
      params: { study_id: undefined, library_id: undefined },
    });

    store.dispatch(tasksActions.setRunningTasks(tasks.data));
  } catch (e) {
    console.error(e);
  }
};

const refTaskState: { intervalId: null | NodeJS.Timeout; lastFetch: number | null } = {
  intervalId: null,
  lastFetch: null,
};

let isFetchTasks = false;

export const startTaskListener = () => {
  const currentFetchTasks = () => {
    if (isFetchTasks) {
      return;
    }
    refTaskState.lastFetch = new Date().valueOf();
    isFetchTasks = true;

    fetchTasks().finally(() => {
      isFetchTasks = false;
    });
  };

  const runTaskLoop = () => {
    if (!refTaskState.intervalId) {
      refTaskState.intervalId = setInterval(currentFetchTasks, TASKS_UPDATE_INTERVAL);
      if (!refTaskState.lastFetch || new Date().valueOf() - refTaskState.lastFetch > TASKS_UPDATE_INTERVAL) {
        currentFetchTasks();
      }
    }
  };

  const stopTaskLoop = () => {
    if (refTaskState.intervalId) {
      clearInterval(refTaskState.intervalId);
      refTaskState.intervalId = null;
    }
  };

  const onVisibilityChange = () => {
    switch (document.visibilityState) {
      case 'visible':
        runTaskLoop();
        break;
      case 'hidden':
        stopTaskLoop();
        break;
    }
  };

  document.addEventListener('visibilitychange', onVisibilityChange);

  runTaskLoop();

  return () => {
    stopTaskLoop();
    document.removeEventListener('visibilitychange', onVisibilityChange);
  };
};
