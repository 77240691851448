import DatePicker, { DatePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';

const DatePickerString = ({ valueFormat, onChange, ...props }: DatePickerProps & DatePickerStringExtraProps) => {
  const customOnChange = (date: DatePickerProps['value']) => {
    if (onChange) {
      onChange(date?.format(valueFormat));
    }
  };

  return (
    <DatePicker
      {...props}
      value={props.value ? dayjs(props.value, valueFormat) : undefined}
      onChange={customOnChange}
    />
  );
};

const { RangePicker } = DatePicker;

export { DatePicker, DatePickerString, RangePicker };

interface DatePickerStringExtraProps {
  value?: number | string;
  valueFormat: string;
  onChange?: (value?: string) => void;
}
