import { appApi } from '@config/appApi';
import { ITask } from '@modules/task/TaskTypes';

export const TasksApiRoutes = {
  tasks: '/api/tasks',
  tasksRunning: '/api/tasks/user',
};

export const TasksApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    tasksList: builder.query<ITask[], { status: string } | void>({
      keepUnusedDataFor: 0,
      query: (params) => ({ params, url: TasksApiRoutes.tasksRunning }),
    }),
  }),
});

export const { useTasksListQuery } = TasksApi;
