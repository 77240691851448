import { useTracedInfoQuery } from '@modules/viewer/duck/viewerApi';
import { Button, DraggableModal, Skeleton, Table, TableExpandableConfig, Tag } from '@ui';
import { ViewerTracedInfoParams } from '@modules/viewer/ViewerTypes';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useMemo, useState } from 'react';
import { Alert, TableColumnsType } from 'antd';
import { startCase } from 'lodash';
import Scrollbars from 'react-custom-scrollbars-2';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { CSSObject } from '@emotion/react';

const getTagColor = (tag: string): string => {
  const title = tag.toLowerCase();

  if (title === 'target') {
    return '#d1ffd6';
  }

  if (title === 'source') {
    return '#daff8f';
  }

  if (title.startsWith('expression')) {
    return '#dac1dd';
  }

  if (title.startsWith('mapping')) {
    return '#f6fdfe';
  }

  return 'green';
};

const expandableOptions: TableExpandableConfig<LineageData> = {
  childrenColumnName: 'childrenRecords',
  defaultExpandAllRows: true,
  expandIcon: ({ expanded, onExpand, record }) =>
    expanded ? (
      <CaretDownOutlined onClick={(e) => onExpand(record, e)} />
    ) : (
      <CaretRightOutlined onClick={(e) => onExpand(record, e)} />
    ),
};

const ViewerModalsTracedContent = ({ data, t }: ViewerModalsTracedContentProps) => {
  const getTracedInfo = useTracedInfoQuery({ ...data });
  const [error, setError] = useState('');

  const lineageData: LineageData[] = useMemo(() => {
    try {
      const items: LineageData[] = getTracedInfo.data ? [JSON.parse(getTracedInfo.data)] : [];
      setError('');
      return items;
    } catch (e) {
      console.error(e);
      setError('Received lineage data has incorrect structure');
      return [];
    }
  }, [getTracedInfo.data]);

  const columns: TableColumnsType<LineageData> = useMemo(
    () => [
      {
        title: t('traced.element'),
        dataIndex: 'recordName',
      },
      {
        width: 100,
        title: t('traced.value'),
        dataIndex: 'value',
      },
      {
        width: 120,
        title: t('traced.type'),
        render: (_, record) => (
          <Tag css={cssTag} color={getTagColor(record.recordType)}>
            {startCase(record.recordType.toLowerCase())}
          </Tag>
        ),
      },
      {
        width: 100,
        title: t('traced.dataType'),
        render: (_, record) => startCase(record.dataType.toLowerCase()),
      },
    ],
    [t],
  );

  if (getTracedInfo.isLoading || getTracedInfo.isFetching) {
    return <Skeleton active />;
  }

  return (
    <>
      <Scrollbars autoHide={false} autoHeight autoHeightMin={500}>
        <Table
          columns={columns}
          dataSource={lineageData}
          expandable={expandableOptions}
          rowKey={(item) => item.recordName}
          tableLayout="fixed"
          pagination={false}
          sticky={true}
        />
      </Scrollbars>
      {error && <Alert message={error} type="error" />}
    </>
  );
};

export const ViewerModalsTraced = ({ open, data, onClose }: ViewerModalsTracedProps) => {
  const { t } = useTranslation(['viewer']);

  return (
    <DraggableModal
      width={1000}
      open={open}
      onCancel={onClose}
      title={t('traced.title')}
      footer={
        <Button type="primary" onClick={onClose}>
          Ok
        </Button>
      }
      destroyOnClose
    >
      {open && <ViewerModalsTracedContent data={data} t={t} />}
    </DraggableModal>
  );
};

export interface ViewerModalsTracedProps {
  open: boolean;
  data: ViewerTracedInfoParams;
  onClose: () => void;
}

interface ViewerModalsTracedContentProps extends Pick<ViewerModalsTracedProps, 'data'> {
  t: TFunction;
}

interface LineageData {
  recordName: string;
  recordType: string;
  value: string | number;
  dataType: string;
  childrenRecords: LineageData[];
}

const cssTag = (): CSSObject => ({
  textWrap: 'pretty',
  color: 'darkslategray !important',
  borderColor: 'lightgray !important',
  height: 'auto',
});
