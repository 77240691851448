import { notification } from 'antd';
import { PropsWithChildren } from 'react';
import { AppContext } from './AppContext';

export const AppProvider = ({ children }: PropsWithChildren) => {
  const [api, contextHolder] = notification.useNotification();

  return (
    <AppContext.Provider value={{ notificationApi: api }}>
      {contextHolder}
      {children}
    </AppContext.Provider>
  );
};
