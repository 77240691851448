import { BaseIcon, SvgIconProps } from '@components/icons/BaseIcon';
import { forwardRef, ForwardRefExoticComponent } from 'react';

export const MigrationIcon: ForwardRefExoticComponent<SvgIconProps> = forwardRef((props, ref) => (
  <BaseIcon {...props} forwardRef={ref} width={24} height={24} fill="none" viewBox="0 0 24 24">
    <path d="M11 16H21" stroke="#12161A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11 16L15 20" stroke="#12161A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11 16L15 12" stroke="#12161A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13 8H3" stroke="#12161A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13 8L9 12" stroke="#12161A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13 8L9 4" stroke="#12161A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </BaseIcon>
));
