import { AuditLogList } from '@modules/audit/components/AuditLogList';
import { useAuditLogListQuery, useExportToExcelMutation } from '@modules/audit/duck/auditApi';
import { Loader, PageTemplateSimple } from '@components';
import { prepareDateRangeFilterForServer, useTablePaginationState } from '@components/ui/table/tableHooks';
import { AuditModalsController } from '@modules/audit/modals/AuditModalsController';
import { ExportButton } from '@components/buttons/ExportButton';
import { Space, TableExtraConfig, TableFiltersConfig, TableSorterConfig, Tag } from '@ui';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { useAppPermissions, useStudyPermissions } from '@modules/user/duck/userHooks';
import { isCrossStudy } from '@shared/utils/common';
import { TablePaginationConfig } from 'antd';
import { useSelector } from 'react-redux';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuditLogItem } from '../AuditTypes';

const initialPage = {
  current: 1,
  pageSize: 10,
  pageSizeOptions: [],
};

const sortOrder: Record<string, string> = {
  ascend: 'asc',
  descend: 'desc',
};

const prepareSorter = (sorter: TableSorterConfig<AuditLogItem>) => {
  const config = Array.isArray(sorter) ? sorter.at(0) : sorter;
  return {
    sort_by: config?.order ? (config?.field as string) : undefined,
    order: sortOrder[config?.order ?? ''],
  };
};

const prepareFilters = (filters: TableFiltersConfig) =>
  Object.fromEntries(
    Object.keys(filters)
      .filter((key) => !!filters[key])
      .map((key) => [key, filters[key]?.join(',')]),
  );

const wrapMixPermissions = (Component: ComponentType<IAuditLogListPageProps>) => {
  const WrapperComponent = () => {
    const globalStudy = useSelector(selectGlobalStudy);
    const {
      appPermissions: { canCrossAuditLogExport },
    } = useAppPermissions();
    const {
      userPermissions: { canAuditLogExport },
    } = useStudyPermissions();

    const crossStudy = isCrossStudy(globalStudy?.id);

    return (
      <Component
        mixCanAuditLogExport={crossStudy ? canCrossAuditLogExport : canAuditLogExport}
        globalStudy={globalStudy}
      />
    );
  };

  return WrapperComponent;
};

export const AuditLogListPage = wrapMixPermissions(({ globalStudy, mixCanAuditLogExport }) => {
  const { t } = useTranslation(['audit']);

  const { paginationState, setPagination, getPagination } = useTablePaginationState(initialPage);
  const [filters, setFilters] = useState<TableFiltersConfig>({});
  const [sorter, setSorter] = useState<TableSorterConfig<AuditLogItem>>({});
  const preparedFilters = { ...prepareSorter(sorter), ...prepareFilters(filters) };
  const auditLogsQuery = useAuditLogListQuery({
    page: paginationState.current - 1,
    ...preparedFilters,
  });
  const [exportToExcel] = useExportToExcelMutation();

  const onTableChange = (
    tablePagination: TablePaginationConfig,
    filters: TableFiltersConfig,
    sorter: TableSorterConfig<AuditLogItem>,
    extra: TableExtraConfig<AuditLogItem>,
  ) => {
    switch (extra.action) {
      case 'paginate':
        setPagination(tablePagination.current!);
        break;
      case 'sort':
        setSorter({ ...sorter });
        break;
      case 'filter':
        setPagination(1);
        const { action_date, ...restFilters } = filters;
        setFilters({ ...restFilters, ...prepareDateRangeFilterForServer(action_date as any) });
        break;
    }
  };

  const pagination = getPagination(auditLogsQuery.data?.totalItems);

  const onExportHandler = async () => {
    const result = await exportToExcel({
      study_id: globalStudy?.id!,
      ...preparedFilters,
    }).unwrap();
    return result.url;
  };

  const isFiltered = Object.values(preparedFilters).some((f) => !!f);

  return (
    <PageTemplateSimple
      hideTitleSkeleton
      title={{
        level: 2,
        children: (
          <Space>
            {t('list.title')}
            {isFiltered && <Tag type="filter" text={t('filtered')} upperCaseText />}
          </Space>
        ),
        refetch: auditLogsQuery.refetch,
        extra: mixCanAuditLogExport && <ExportButton name={t('list.title')} onExport={onExportHandler} />,
      }}
      content={{
        isLoading: auditLogsQuery.isLoading && !auditLogsQuery.data,
        errorText: t('errors.pageLoadingError'),
        error: auditLogsQuery.error,
      }}
    >
      {auditLogsQuery.isFetching && !auditLogsQuery.isLoading && <Loader mode="absolute" />}
      {auditLogsQuery.data && (
        <AuditLogList
          data={auditLogsQuery.data}
          studyId={globalStudy?.id!}
          onChange={onTableChange}
          pagination={pagination}
        />
      )}
      <AuditModalsController />
    </PageTemplateSimple>
  );
});

interface IAuditLogListPageProps {
  mixCanAuditLogExport?: boolean;
  globalStudy: ReturnType<typeof selectGlobalStudy>;
}
