import { appAxios } from '@config/AppConfig';
import { RootState, store } from '@store';
import { composeActions } from '@app/components/Notifications/duck/hooks/messages';
import { isEqual } from 'lodash';
import { TNotification, TRawNotification, NotificationsApiRoutes } from './notificationsApi';
import { selectNotificationsArchived, selectNotificationsLatest } from './notificationsSelector';
import { notificationsActions } from './notificationsSlice';
import { isAnotherAppEvent, isDatastageEvent, shouldAutoclick } from './notificationsUtils';

const NOTIFICATIONS_UPDATE_INTERVAL = 10000;

export const fetchNotifications = async () => {
  try {
    const rootState: RootState = store.getState();
    const notificationsDisplayed: string[] = (localStorage.getItem('notificationsDisplayed') ?? '').split(',');

    if (!rootState.app.ready || rootState.app.isUnauthorized) {
      return;
    }

    const notifications = await appAxios.get<TRawNotification[]>(NotificationsApiRoutes.notifications, {
      params: { study_id: undefined, library_id: undefined },
    });

    const notificationMap: NotificationMap = {
      latest: [],
      archived: [],
    };

    const notificationIds = notifications.data.map((item) => item.notificationId);
    const notificationUnreadIds: string[] = [];

    for (let item of notifications.data) {
      let extra = { comment: item.extra };
      try {
        extra = JSON.parse(item.extra);
      } catch {}
      if (isDatastageEvent(extra) || isAnotherAppEvent(extra)) {
        if (item.isArchived) {
          notificationMap.archived.push({ ...item, extra });
        } else {
          if (!notificationsDisplayed.includes(item.notificationId) && shouldAutoclick(extra)) {
            const actions = composeActions(extra);
            if (actions && !item.isRead) {
              actions[0].onClick();
              notificationUnreadIds.push(item.notificationId);
            }
          }
          notificationMap.latest.push({ ...item, extra });
        }
      }
    }

    if (notificationUnreadIds.length) {
      localStorage.setItem(
        'notificationsDisplayed',
        [
          ...new Set(
            [...notificationUnreadIds, ...notificationsDisplayed].filter((item) => notificationIds.includes(item)),
          ),
        ].join(','),
      );
      await appAxios.post(NotificationsApiRoutes.notifications, {
        notification_ids: notificationUnreadIds,
      });
    }

    if (!isEqual(selectNotificationsLatest(rootState), notificationMap.latest)) {
      store.dispatch(notificationsActions.setLatest(notificationMap.latest));
    }

    if (!isEqual(selectNotificationsArchived(rootState), notificationMap.archived)) {
      store.dispatch(notificationsActions.setArchived(notificationMap.archived));
    }
  } catch (e) {
    console.error(e);
  }
};

const refNotificationState: { intervalId: null | NodeJS.Timeout; lastFetch: number | null } = {
  intervalId: null,
  lastFetch: null,
};

let isFetchNotifications = false;

export const startNotificationListener = () => {
  const currentFetchNotifications = () => {
    if (isFetchNotifications) {
      return;
    }
    refNotificationState.lastFetch = new Date().valueOf();
    isFetchNotifications = true;

    fetchNotifications().finally(() => {
      isFetchNotifications = false;
    });
  };

  const runNotificationLoop = () => {
    if (!refNotificationState.intervalId) {
      refNotificationState.intervalId = setInterval(currentFetchNotifications, NOTIFICATIONS_UPDATE_INTERVAL);
      if (
        !refNotificationState.lastFetch ||
        new Date().valueOf() - refNotificationState.lastFetch > NOTIFICATIONS_UPDATE_INTERVAL
      ) {
        currentFetchNotifications();
      }
    }
  };

  const stopNotificationLoop = () => {
    if (refNotificationState.intervalId) {
      clearInterval(refNotificationState.intervalId);
      refNotificationState.intervalId = null;
    }
  };

  const onVisibilityChange = () => {
    switch (document.visibilityState) {
      case 'visible':
        runNotificationLoop();
        break;
      case 'hidden':
        stopNotificationLoop();
        break;
    }
  };

  document.addEventListener('visibilitychange', onVisibilityChange);

  runNotificationLoop();

  return () => {
    stopNotificationLoop();
    document.removeEventListener('visibilitychange', onVisibilityChange);
  };
};

type NotificationMap = Record<'latest' | 'archived', TNotification[]>;
