import { App } from '@app/App';
import { FallbackRender } from '@app/components';
import { appActions } from '@app/duck/appSlice';
import { PageNotFound } from '@components';
import { appApi, invalidateStudyQueryTags } from '@config/appApi';
import { setAppAxiosConfigs } from '@config/AppConfig';
import routes from '@routes';
import { RoutesStudyApp, RoutesCrossStudyApp } from '@routes/study/RoutesStudyApp';
import { RoutesLibraryApp } from '@routes/library/RoutesLibraryApp';
import { RootState, store } from '@store';
import { createBrowserRouter, Location, matchPath } from 'react-router-dom';
import { isValidStudyId } from './utils';

const appBrowserRouter = {
  path: '/',
  element: <App />,
  errorElement: <FallbackRender />,
  children: [
    ...RoutesLibraryApp,
    ...RoutesStudyApp,
    ...RoutesCrossStudyApp,
    {
      path: '*',
      element: <PageNotFound />,
    },
  ],
};

export const router = createBrowserRouter([appBrowserRouter], {
  future: {
    v7_relativeSplatPath: true,
  },
});

let prevAppContextState: { current: { id?: string; type?: string } } = { current: {} };
const getPreloadedAppContext = (location: Location) => {
  const invalidateQueryCache = () => {
    store.dispatch(appApi.util.invalidateTags(invalidateStudyQueryTags));
  };

  let studyParams = matchPath({ path: routes.study.view.path, end: false }, location.pathname)?.params;
  if (!studyParams?.studyId) {
    studyParams = matchPath({ path: routes.crossStudy.view.path, end: false }, location.pathname)?.params;
  }

  if (studyParams?.studyId) {
    if (prevAppContextState.current.id !== studyParams?.studyId || prevAppContextState.current.type === 'library') {
      const studyId = Number(studyParams?.studyId);
      if (isValidStudyId(studyId)) {
        setAppAxiosConfigs({ study_id: studyId });
        store.dispatch(
          appActions.setContext({
            type: 'study',
            context: {
              id: studyId,
              ...(location.state?.name
                ? {
                    name: location.state?.name,
                    studySchema: location.state?.studySchema,
                    protocolId: location.state?.protocolId,
                    configured: location.state?.configured,
                  }
                : {}),
            },
          }),
        );
      }

      requestAnimationFrame(invalidateQueryCache);
    }

    prevAppContextState.current.id = studyParams?.studyId;
    prevAppContextState.current.type = 'study';
    return;
  }
  const libraryParams = matchPath(
    { path: require('@routes/library/RoutesLibrary').default.view.path, end: false },
    location.pathname,
  )?.params;

  if (libraryParams?.libraryId) {
    if (prevAppContextState.current.id !== libraryParams?.libraryId || prevAppContextState.current.type === 'study') {
      const libraryId = Number(libraryParams?.libraryId);
      if (!Number.isNaN(libraryId)) {
        setAppAxiosConfigs({ library_id: libraryId });
        store.dispatch(
          appActions.setContext({
            type: 'library',
            context: {
              id: libraryId,
              ...(location.state?.name ? { name: location.state?.name, status: location.state?.status } : {}),
            },
          }),
        );
      }

      requestAnimationFrame(invalidateQueryCache);
    }

    prevAppContextState.current.id = libraryParams?.libraryId;
    prevAppContextState.current.type = 'library';
    return;
  }

  const context = (store.getState() as RootState).app.context;

  if (context.library || context.study) {
    setAppAxiosConfigs({});
    store.dispatch(
      appActions.setContext({
        type: 'study',
        context: null,
      }),
    );
    prevAppContextState.current = {};
  }
};

router.subscribe(({ location }) => getPreloadedAppContext(location));
getPreloadedAppContext(router.state.location);
